import {useEffect, useState} from "react";
import {useGetPortfolioPropertyDiffLazyQuery, VersionType} from "../../../../__generated__/generated_types";
import {Property} from "../../../../contexts/properties/PropertiesContext";
import {FinancialEntity} from "../../../../contexts/chartofaccounts/ChartOfAccountsContext";
import {formatterGlName} from "../../../../utils/formatters";
import {TData} from "./logic";

interface IDataLoader {
    properties: Property[] | undefined;
    chartOfAccountsFlat: FinancialEntity[] | undefined;
}

interface ILoadData {
    year: number;
    versionType: VersionType.Reforecast | VersionType.Budget;
    idealPropertyId: string;
    orderedPropertyIds: string[];
}

export function useDataLoader(props: IDataLoader) {
    const [getPortfolioPropertyDiff, {data: rawDiffData, loading: rawDiffDataLoading, error: rawDiffDataError}] = useGetPortfolioPropertyDiffLazyQuery({
        fetchPolicy: "no-cache"
    });

    const [diffData, setDiffData] = useState<{rows: TData[], createdAt: Date, jobKey: string} | null>();
    const [diffDataLoading, setDiffDataLoading] = useState(false);

    function loadData(params: ILoadData) {
        setDiffDataLoading(true);
        getPortfolioPropertyDiff({
            variables: {
                year: params.year,
                versionType: params.versionType,
                idealPropertyId: params.idealPropertyId,
                orderedPropertyIds: params.orderedPropertyIds
            }
        });
    }

    useEffect(() => {
        if(!rawDiffData || rawDiffDataLoading || !props.chartOfAccountsFlat || !props.properties) {
            return;
        }
        if(!rawDiffData.getPortfolioPropertyDiff) {
            setDiffData(null);
            setDiffDataLoading(false);
            return;
        }
        if(rawDiffDataError) {
            setDiffData(null);
            setDiffDataLoading(false);
            return;
        }
        const loaded: TData[] = [];
        for(const account of props.chartOfAccountsFlat) {
            const accountData = rawDiffData.getPortfolioPropertyDiff.compareResult.accounts.find(a => a.accountId == account.id);
            if (!accountData) {
                continue;
            }
            const property = props.properties.find(p => p.id == accountData.firstDiffPropertyId);
            if (!property) {
                continue;
            }
            loaded.push({
                account: {
                    id: account.id,
                    name: formatterGlName(account.name, account.number)
                },
                property: {
                    name: property.name,
                    total: accountData.totalDiffProperties
                }
            });
        }
        setDiffData({rows: loaded, createdAt: rawDiffData.getPortfolioPropertyDiff.createdAt, jobKey: rawDiffData.getPortfolioPropertyDiff.jobKey});
        setDiffDataLoading(false);

    }, [rawDiffData, rawDiffDataLoading, rawDiffDataError, props.chartOfAccountsFlat, props.properties]);

    return {
        diffData,
        diffDataLoading,
        loadData
    };
}