import {ReactElement, ReactNode, useContext, useEffect, useMemo} from "react";
import * as css from "./propertydiff.module.scss";
import {FinancialEntityType, JobStatus, VersionType} from "../../../../__generated__/generated_types";
import {DataDisplayTable} from "../../../../components/data-table/DataDisplayTable";
import {usePagination} from "../../../../components/data-table/usePagination";
import {useProperties} from "../../../../contexts/properties/PropertiesContext";
import {useDataLoader} from "./DataLoader";
import {Button} from "@zendeskgarden/react-buttons";
import {AuthContext} from "../../../../contexts/AuthContext";
import {useChartOfAccounts} from "../../../../contexts/chartofaccounts/ChartOfAccountsContext";
import {rowKey, TData} from "./logic";
import {useDataCreator} from "./DataCreator";
import {Link} from "react-router-dom";
import {Inline} from "@zendeskgarden/react-loaders";
import {ReactComponent as AlertIcon} from '@zendeskgarden/svg-icons/src/16/alert-warning-fill.svg';
import {COLORS} from "../../../../constants/Colors";
import cn from "classnames";
import {formatterDateTimeUS} from "../../../../utils/formatters";
import {Tooltip} from "@zendeskgarden/react-tooltips";

export interface PropertyDiffParams {
    budgetYear: number;
    versionType: VersionType.Reforecast | VersionType.Budget;
    headerRightComponent?: ReactNode;
    customLabelComponent?: ReactNode;
}

export function PropertyDiff(props: PropertyDiffParams): ReactElement {
    const {properties, currentProperty, loaded: propertiesLoaded} = useProperties();
    const {chartOfAccountsFlat, isReady: coaReady} = useChartOfAccounts();
    const authContext = useContext(AuthContext);
    const dataLoader = useDataLoader({properties: properties, chartOfAccountsFlat});
    const dataCreator = useDataCreator({userId: authContext?.user?.id ?? null, budgetYear: props.budgetYear});
    const pagination = usePagination();
    function cellDataToDisplay(key: keyof TData, row: TData): string | ReactElement | number {
        if(key === "account") {
            return (
                <div>
                    <Link
                        className={css.accountLink}
                        target="_blank"
                        to={`/modeling_analysis/account?initial_benchmark_property_id=${currentProperty?.id}&initial_account_id=${row.account.id}&initial_version_type=${props.versionType}&hide_empty_columns`}>
                        {row.account.name}
                    </Link>
                </div>
            );
        }
        return `${row.property.name} ${row.property.total > 1 ? `+${row.property.total - 1} more...` : ""}`;
    }

    function handleCreateData() {
        dataCreator.submittingCreateRequest();
    }

    function handleLoadData() {
        const currentPropertyId = currentProperty?.id;
        if(!chartOfAccountsFlat || !coaReady || !properties || !currentPropertyId || !propertiesLoaded) {
            return;
        }

        let year = props.budgetYear;
        if(props.versionType === VersionType.Reforecast) {
            year = props.budgetYear - 1;
        }
        dataLoader.loadData({
            year: year,
            versionType: props.versionType,
            idealPropertyId: currentPropertyId,
            orderedPropertyIds: properties.filter(p => p.id !== currentPropertyId).map(p => p.id)
        });
    }

    useEffect(() => {
        handleLoadData();
    }, [chartOfAccountsFlat, coaReady, properties, currentProperty?.id, props.budgetYear, props.versionType, propertiesLoaded]);

    useEffect(() => {
        const diffData = dataLoader.diffData;
        const createJob = dataCreator.createJob;

        if(diffData === undefined) {
            return;
        }
        if(diffData == null && createJob?.status === JobStatus.Completed
            || diffData !== null
            && createJob
            && createJob.status === JobStatus.Completed
            && diffData.jobKey !== createJob.id
        ) {
            dataCreator.reset();
            handleLoadData();
        }
    }, [dataLoader.diffData, dataCreator.createJob]);

    useEffect(() => {
        const dataLength = dataLoader.diffData?.rows?.length;
        if(dataLength !== undefined) {
            pagination.set({total: dataLength, currentPage: 1});
        }
    }, [dataLoader.diffData?.rows?.length]);

    const pageData = useMemo(() => {
        if(!dataLoader.diffData) {
            return undefined;
        }
        const start = (pagination.paginationParams.currentPage - 1) * pagination.paginationParams.pageSize;
        return dataLoader.diffData.rows.slice(start, start + pagination.paginationParams.pageSize);
    }, [dataLoader.diffData, pagination.paginationParams.currentPage]);

    const updateButton = useMemo(() => {
        let failedAlert = null;
        const createJob = dataCreator.createJob;
        let waitingTooLong = false;
        if(createJob && !createJob.ended) {
            const createdAt = new Date(createJob.createdAt).getTime();
            // waiting more than 60 seconds
            // let them try again
            // the system will take care of itself and not overwelm by multiple jobs
            // as only some of them will really get fully executed
            waitingTooLong = (Date.now() - createdAt > 60000);
        }
        if(createJob?.status === JobStatus.Failed || waitingTooLong) {
            let hint = "Something went wront. Please try again.";
            if(waitingTooLong) {
                hint = "Our servers look busy. Please try again.";
            }
            failedAlert = (
                <Tooltip zIndex={10} content={hint}>
                    <AlertIcon style={{color: "var(--yellow-200)"}} />
                </Tooltip>
            );
        }
        let label = <>Update</>;
        if(createJob
            && createJob.status !== JobStatus.Failed
            && !createJob.ended
            && !waitingTooLong
        ) {
            label = <>Updating&nbsp;<Inline color={COLORS.PRIMARY_400} aria-label="loading" /></>;
        }
        let buttonDisabled = false;
        if(!waitingTooLong) {
            buttonDisabled = !dataCreator.canSubmitCreateRequest || dataLoader.diffDataLoading || dataLoader.diffData === undefined;
        }
        return (
            <Button
                disabled={buttonDisabled}
                onClick={handleCreateData}
            >
                {label} {failedAlert}
            </Button>
        );
    }, [dataCreator.createJob, dataCreator.canSubmitCreateRequest, dataLoader.diffDataLoading, dataLoader.diffData]);

    return (
        <div className={css.wrapper}>
            <div className={cn(css.header, dataLoader.diffDataLoading ? css.flashBg : undefined)}>
                {props.customLabelComponent ?? <h4>{PropertyDiff.label}</h4>}
                {currentProperty ?
                    <h4>&nbsp;|&nbsp;{currentProperty.name}</h4>
                    : ""
                }
                {dataLoader.diffData && chartOfAccountsFlat ?
                    <h4>&nbsp;|&nbsp;Different&nbsp;Accounts:&nbsp;{dataLoader.diffData.rows.length + " out of " + chartOfAccountsFlat.filter(a => a.type === FinancialEntityType.Account).length}</h4>
                    : ""
                }
                {dataLoader.diffDataLoading ?
                    <h4>&nbsp;|&nbsp;Loading&nbsp;<Inline color={COLORS.PRIMARY_400} aria-label="loading" /></h4>
                    : null
                }
                <div className={css.headerButtons}>
                    {dataLoader.diffData ?
                        <span className={css.snapshotDate}>Data current as of {formatterDateTimeUS(dataLoader.diffData.createdAt)}</span>
                        : ""
                    }
                    {updateButton}
                    {props.headerRightComponent}
                </div>
            </div>
            <div className={css.container}>
                {dataLoader.diffData === undefined || dataLoader.diffData && dataLoader.diffData.rows.length > 0 ?
                    <DataDisplayTable
                        className={css.tableWrapper}
                        dataProvider={{
                            rows: pageData,
                            totalRows: !dataLoader.diffData ? undefined : Math.min(pagination.paginationParams.pageSize, dataLoader.diffData.rows.length),
                            columns: ["account", "property"],
                            secondHeaderRow: undefined
                        }}
                        headers={{
                            property: "Property",
                            account: "Account (click account to see details)",
                        }}
                        paginationParams={{
                            params: pagination.paginationParams,
                            setPage: pagination.setPage
                        }}
                        sortingParams={undefined}
                        customCellRenderers={{
                            cellDataToDisplay: cellDataToDisplay,
                            rowKey: rowKey
                        }}
                    />
                    :
                    <div className={css.noDataPane}>
                        {dataLoader.diffData && dataLoader.diffData.rows.length === 0 ?
                            <span>There are no differences detected</span>
                            :
                            <>
                                <span>There is no information yet</span><br />
                                {updateButton}
                            </>
                        }
                    </div>
                }
            </div>
        </div>
    );
}

PropertyDiff.label = "FIND PORTFOLIO DIFFERENCES";
