export type TData = {
    account: {
        id: string;
        name: string;
    };
    property: {
        name: string;
        total: number;
    }
};

export function rowKey(row: TData): string | number {
    return row.account.id;
}
