import {ReactElement, useMemo, useState} from "react";
import {useProperties} from "../../contexts/properties/PropertiesContext";
import {VersionType} from "../../__generated__/generated_types";
import {PropertyRevenueDriverAccounts} from "./components/revenue/PropertyRevenueDriverAccounts";
import * as css from "./modelinganalysis.module.scss";
import {ThemeProvider} from "@zendeskgarden/react-theming";
import {ViziblyTheme} from "../../styles/zendesk-garden/ViziblyZDGTheme";
import {Dropdown, Field, Item, Menu, Select} from "@zendeskgarden/react-dropdowns";
import {PropertyPayrollDriverAccounts} from "./components/payroll/PropertyPayrollDriverAccounts";
import {useHistory, useRouteMatch} from "react-router-dom";
import {AccountDrivers} from "./components/account/AccountDrivers";
import {PropertyDiff} from "./components/property/PropertyDiff";

function getLabel(page: string): string {
    if (page === "payroll") {
        return PropertyPayrollDriverAccounts.label;
    }
    if (page === "revenue") {
        return PropertyRevenueDriverAccounts.label;
    }
    if (page === "account") {
        return AccountDrivers.label;
    }
    if (page === "property") {
        return PropertyDiff.label;
    }
    return "";
}

export function ModelingAnalysis(): ReactElement | null {
    const {currentProperty} = useProperties();
    const routeMatch = useRouteMatch<{what: string}>("/modeling_analysis/:what");
    const what = !routeMatch?.params.what ? "property" : ["payroll", "revenue", "account", "property"].includes(routeMatch?.params.what) ? routeMatch?.params.what : "property";
    const urlInitialVersionType = new URL(window.location.href).searchParams.get("initial_version_type");
    let initialVersionType = undefined;
    if (urlInitialVersionType === VersionType.Budget || urlInitialVersionType === VersionType.Reforecast) {
        initialVersionType = urlInitialVersionType as (VersionType.Reforecast | VersionType.Budget);
    }
    const history = useHistory();
    const [versionType, setVersionType] = useState<VersionType.Reforecast | VersionType.Budget>(initialVersionType ?? VersionType.Budget);

    const periodSelector = useMemo(() => {
        if (!currentProperty) {
            return null;
        }
        return (
            <Dropdown
                selectedItem={versionType}
                onSelect={(v) => setVersionType(v)}
            >
                <Field className={css.dropdownField}>
                    <Select className={css.dropdownSelect}>
                        <div className={css.dropdownLabel}>
                            Forecast Period
                        </div>
                        <div className={css.selectedItem}>
                            {versionType} {versionType === VersionType.Reforecast ? currentProperty.reforecastYear : currentProperty.budgetYear}
                        </div>
                    </Select>
                </Field>
                <Menu>
                    <Item key={VersionType.Reforecast} value={VersionType.Reforecast}>
                        {VersionType.Reforecast}
                    </Item>
                    <Item key={VersionType.Budget} value={VersionType.Budget}>
                        {VersionType.Budget}
                    </Item>
                </Menu>
            </Dropdown>
        );
    }, [versionType, currentProperty]);

    const sectionSelector = useMemo(() => {
        return (
            <Dropdown
                selectedItem={what}
                onSelect={(v) => history.push(`/modeling_analysis/${v}`)}
            >
                <Field className={css.sectionDropdownField}>
                    <Select className={css.dropdownSelect}>
                        <h4>{getLabel(what)}</h4>
                    </Select>
                </Field>
                <Menu>
                    <Item key="property" value="property">
                        {PropertyDiff.label}
                    </Item>
                    <Item key="account" value="account">
                        {AccountDrivers.label}
                    </Item>
                    <Item key="revenue" value="revenue">
                        {PropertyRevenueDriverAccounts.label}
                    </Item>
                    <Item key="payroll" value="payroll">
                        {PropertyPayrollDriverAccounts.label}
                    </Item>
                </Menu>
            </Dropdown>
        );
    }, [what]);

    if (!currentProperty) {
        return null;
    }

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={css.wrapper}>
                {routeMatch?.params?.what === "payroll" ?
                    <PropertyPayrollDriverAccounts
                        budgetYear={currentProperty.budgetYear}
                        customLabelComponent={sectionSelector}
                    />
                    :
                    routeMatch?.params?.what === "account" ?
                    <AccountDrivers
                        budgetYear={currentProperty.budgetYear}
                        versionType={versionType}
                        headerRightComponent={periodSelector}
                        customLabelComponent={sectionSelector}
                    />
                    :
                    routeMatch?.params?.what === "revenue" ?
                    <PropertyRevenueDriverAccounts
                        budgetYear={currentProperty.budgetYear}
                        versionType={versionType}
                        headerRightComponent={periodSelector}
                        customLabelComponent={sectionSelector}
                    />
                    :
                    <PropertyDiff
                        budgetYear={currentProperty.budgetYear}
                        versionType={versionType}
                        headerRightComponent={periodSelector}
                        customLabelComponent={sectionSelector}
                    />
                }
            </div>
        </ThemeProvider>
    );
}