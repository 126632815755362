import {useEffect, useState} from "react";
import {
    BackgroundJob,
    useGetDataCreationStatusLazyQuery,
    useGetJobLazyQuery,
    useSubmitCreatePortfolioCompareSnapshotMutation
} from "../../../../__generated__/generated_types";

export interface IDataCreator {
    userId: string | null;
    budgetYear: number;
}

export function useDataCreator(props: IDataCreator) {
    const [submitCreatePortfolioCompareSnapshot] = useSubmitCreatePortfolioCompareSnapshotMutation();
    const [getDataCreationStatus, {data: creationStatusData, loading: creationStatusLoading}] = useGetDataCreationStatusLazyQuery({
        fetchPolicy: "no-cache"
    });
    const [getJob, {data: backgroundJobData, loading: backgroundJobLoading}] = useGetJobLazyQuery({
        fetchPolicy: "no-cache"
    });
    const [createJobId, setCreateJobId] = useState<string | null>(null);
    const [createJob, setCreateJob] = useState<BackgroundJob | null>(null);
    const [fetchingJobId, setFetchingJobId] = useState(false);

    function reset() {
        setCreateJobId(null);
        setCreateJob(null);
    }

    function submitBackgroundJob() {
        setFetchingJobId(true);
        setCreateJob(null);
        submitCreatePortfolioCompareSnapshot({
            variables: {
                budgetYear: props.budgetYear,
            }
        }).then(resp => {
            if(resp.data?.submitCreatePortfolioCompareSnapshot) {
                const job = resp.data?.submitCreatePortfolioCompareSnapshot;
                setCreateJobId(job.id);
            } else {
                setFetchingJobId(false);
            }
        }).catch(() => {
            setFetchingJobId(false);
        });
    }

    useEffect(() => {
        if(backgroundJobLoading || !backgroundJobData?.getJob) {
            return;
        }

        const job = backgroundJobData.getJob;
        if(job.ended) {
            setCreateJobId(null);
        }

        setCreateJob(job);
        setFetchingJobId(false);
    }, [backgroundJobData, backgroundJobLoading]);

    useEffect(() => {
        if(createJobId === null) {
            return;
        }

        const interval = setInterval(() => {
            getJob({
                variables: {
                    jobId: createJobId
                }
            });
        }, 2000);

        return () => clearInterval(interval);
    }, [createJobId]);

    useEffect(() => {
        if(!props.userId || createJob) {
            return;
        }
        setFetchingJobId(true);
        getDataCreationStatus({
            variables: {
                budgetYear: props.budgetYear,
                userId: props.userId
            }
        });
    }, [props.userId]);

    useEffect(() => {
        if(!creationStatusData || creationStatusLoading) {
            return;
        }

        setCreateJobId(creationStatusData.listJobs?.items[0]?.id ?? null);
        setFetchingJobId(false);
    }, [creationStatusData, creationStatusLoading]);

    const canSubmitCreateRequest = !fetchingJobId && (!createJob || createJob?.ended);

    return {
        createJob,
        canSubmitCreateRequest,
        submittingCreateRequest: submitBackgroundJob,
        reset
    };
}